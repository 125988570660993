// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap");

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";
@import "~admin-lte/dist/css/adminlte.css";

// Font Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";

// sweet alert
@import "sweetalert2/src/sweetalert2.scss";
// import 'sweetalert2/src/sweetalert2.scss'

.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.blue {
  color: $blue;
}

.indigo {
  color: $indigo;
}

.purple {
  color: $purple;
}

.pink {
  color: $pink;
}

.red {
  color: $red;
}

.orange {
  color: $orange;
}

.yellow {
  color: $yellow;
}

.green {
  color: $green;
}

.teal {
  color: $teal;
}

.cyan {
  color: $cyan;
}

body {
  background-color: #ffffff;
  font-family: Inter;
}

// custom checkbox
@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  .checkbox-wrapper input[type="checkbox"] {
    --active: #275efe;
    --active-inner: #fff;
    --focus: 2px rgba(39, 94, 254, 0.3);
    --border: #bbc1e1;
    --border-hover: #275efe;
    --background: #fff;
    --disabled: #f6f8ff;
    --disabled-inner: #e1e6f9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 21px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
  }

  .checkbox-wrapper input[type="checkbox"]:after {
    content: "";
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
      opacity var(--d-o, 0.2s);
  }

  .checkbox-wrapper input[type="checkbox"]:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: 0.3s;
    --d-t: 0.6s;
    --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
  }

  .checkbox-wrapper input[type="checkbox"]:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;
  }

  .checkbox-wrapper input[type="checkbox"]:disabled:checked {
    --b: var(--disabled-inner);
    --bc: var(--border);
  }

  .checkbox-wrapper input[type="checkbox"]:disabled+label {
    cursor: not-allowed;
  }

  .checkbox-wrapper input[type="checkbox"]:hover:not(:checked):not(:disabled) {
    --bc: var(--border-hover);
  }

  .checkbox-wrapper input[type="checkbox"]:focus {
    box-shadow: 0 0 0 var(--focus);
  }

  .checkbox-wrapper input[type="checkbox"]:not(.switch) {
    width: 21px;
  }

  .checkbox-wrapper input[type="checkbox"]:not(.switch):after {
    opacity: var(--o, 0);
  }

  .checkbox-wrapper input[type="checkbox"]:not(.switch):checked {
    --o: 1;
  }

  .checkbox-wrapper input[type="checkbox"]+label {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    margin-left: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #b0b7c3;
  }

  .checkbox-wrapper input[type="checkbox"]:not(.switch) {
    border-radius: 7px;
  }

  .checkbox-wrapper input[type="checkbox"]:not(.switch):after {
    width: 5px;
    height: 9px;
    border: 2px solid var(--active-inner);
    border-top: 0;
    border-left: 0;
    left: 7px;
    top: 4px;
    transform: rotate(var(--r, 20deg));
  }

  .checkbox-wrapper input[type="checkbox"]:not(.switch):checked {
    --r: 43deg;
  }
}

.checkbox-wrapper * {
  box-sizing: inherit;
}

.checkbox-wrapper *:before,
.checkbox-wrapper *:after {
  box-sizing: inherit;
}

// Auth page
.register-page {
  background: url("/images/auth-bg.png") !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;

  .register-box {
    width: 410px;
  }

  .card {
    border-radius: 15px;
    overflow: hidden;

    .register-card-body {
      padding: 60px 30px;

      .login-title {
        font-family: Inter;
        font-size: 26px;
        line-height: 29px;
        font-weight: 700;
        text-align: center;
        color: #323b4b;
        margin-bottom: 25px;
      }

      .login-box-msg {
        font-weight: 500;
        font-size: 18px;
        line-height: 29px;
        color: #8a94a6;
        margin-bottom: 50px;
        padding: 0 20px;
      }

      .input-group {
        position: relative;
        margin-bottom: 30px;

        .form-control {
          height: 58px;
          background: #ffffff;
          border-radius: 15px;
          padding: 8px 40px;
          border: 1px;
          border: 1px solid #f3f3f3;

          &:focus {
            border: 1px solid #377dff;
            box-shadow: 0px 12px 23px rgba(55, 125, 255, 0.06);
          }
        }

        .pre-icon {
          position: absolute;
          top: 22px;
          left: 15px;
          z-index: 10;
        }

        .after-icon {
          position: absolute;
          top: 22px;
          right: 15px;
          z-index: 10;
        }
      }

      .checkbox-wrapper {
        margin-bottom: 30px;
      }

      .btn-primary {
        background-color: #377dff;
        border-color: #377dff;
        height: 58px;
        border-radius: 15px;
        font-family: "Inter";
        font-weight: 600;
        font-size: 16px;
        line-height: 29px;
        margin-bottom: 25px;
      }

      .authform-link {
        text-align: right;

        a {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: right;
          color: #377dff;
        }
      }
    }
  }
}

// Dashboard layout
.app-wrapper {
  max-width: 1080px;
  margin: 0 auto;
  // margin-left: 300px; // for development
}

section {
  .section-title {
    font-family: "Inter";
    font-weight: 600;
    font-size: 32px;
    color: #323b4b;
  }
}

// Form controls
.form-control {
  &+label {
    font-weight: 500 !important;
    font-size: 18px;
    line-height: 29px;
    color: #8a94a6;
  }
}

.tags-container {
  label {
    font-weight: 500 !important;
    font-size: 18px;
    line-height: 29px;
    color: #8a94a6;
    margin-bottom: 0;
    padding: 0 20px;
  }
}

.input-box {
  width: 100%;
  height: 60px;
  background: #ffffff;
  border: 1px solid #c6c6c6;
  border-radius: 15px;
}

.textarea {
  width: 100%;
  background: #ffffff;
  border: 1px solid #c6c6c6;
  border-radius: 15px;
}

.btn-primary {
  background: #377dff;
  border-radius: 15px;
  height: 60px;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  color: #ffffff;
}

.btn-danger {
  border-radius: 15px;
  height: 60px;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  color: #ffffff;
  padding: 16px;
}

.btn-white {
  background: transparent;
  border-radius: 15px;
  height: 60px;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  color: #999999;
}

// Vue tags input
.tags-container {
  .vue-tags-input {
    max-width: 585px !important;
    border-radius: 15px;

    .ti-input {
      border-radius: 15px;
      padding: 12px;

      .ti-tags {
        .ti-tag {
          background-color: #d9d9d9;
          padding: 3px 10px;

          .ti-content {
            font-weight: 500;
            font-size: 14px;
            line-height: 29px;
            color: #606469;
          }

          .ti-icons i {
            font-size: 18px;
            color: #131313;
          }

          &.ti-deletion-mark {
            background-color: #e54d42;

            .ti-content {
              color: #ffffff;
            }
          }
        }

        .ti-new-tag-input {
          font-size: 14px;
        }
      }
    }
  }

  &.is-invalid {
    .vue-tags-input {
      .ti-input {
        border-color: #e3342f;
        padding-right: calc(1.6em + 0.75rem) !important;
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e3342f' viewBox='0 0 12 12'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='%23e3342f' stroke='none'/></svg>");
        background-repeat: no-repeat;
        background-position: right calc(0.4em + 0.1875rem) center;
        background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
      }
    }
  }
}

.is-invalid .invalid-feedback,
.is-invalid .invalid-tooltip {
  display: block;
}

.invalid-feedback {
  padding: 0 20px;
}